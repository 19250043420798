import React from "react";
import Layout from "../../layouts/layout";
import Banner from "../../components/banner";
import SustainabilityVisionDesc from "../../components/sustainabilityVisionDesc";
import SEO from "../../components/seo";

const Vision = () => {
  return (
    <>
      <SEO
        title="Sustainability Vision"
        metaDesc="We are dedicated to leaving a positive footprint on society, the economy, and the environment while setting the benchmark for innovation through transparent leadership."
        canonical="/sustainability_vision"
      />
      <Layout>
        <Banner
          title="Sustainability"
          titleBlue=" Vision"
          imageUrl=""
          imageBool={false}
        />

        <SustainabilityVisionDesc />
      </Layout>
    </>
  );
};

export default Vision;
