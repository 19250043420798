import React from "react";
import Layout from "../../layouts/layout";
import Banner from "../../components/banner";
import MiningDesc from "../../components/miningDesc";
import SEO from "../../components/seo";


const MiningTunneling = () => {
  return (
    <>
    <SEO title="Redefining Excellence in Chemical Solutions for Mining and Tunneling" metaDesc="Mining 4.0TM and Tunneling 4.0TM embody a groundbreaking approach within the mining and tunneling sectors." canonical="/mining_tunneling" />
      <Layout>
        <Banner
          title="Mining"
          titleBlue=" 4.0TM"
          title2=" Tunneling"
          titleBlue2=" 4.0TM"
          imageUrl="/assets/images/4.0.png"
          tradeMark={true}
          imageBool={true}
        />

      <MiningDesc/>


      </Layout>
    </>
  );
};

export default MiningTunneling;
