import React from "react";
import PropTypes from "prop-types";
import "./index.css";

const Frame = ({
  imageUrl,
  imageAlt,
  reverse,
  titleWhite,
  titleBlue,
  description,
}) => {
  return (
    <>
      <div className="about-us">
        <div
          className={
            reverse
              ? "image-frame-box-image-text-box flex-row-reverse"
              : "image-frame-box-image-text-box"
          }
        >
          <div className="image-frame-box col-4">
            <div className="main-border-div">
              <div className="main-border">
                <div className="border-container">
                  <div className="border-top-left" />
                  <div className="border-bottom-right" />
                  <div className="content">
                    <img
                      className="image-image"
                      src={imageUrl}
                      alt={imageAlt}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="image-text-box col-8">
            <div
              className={
                reverse
                  ? "image-text-box-title"
                  : "image-text-box-title"
              }
            >
              <h3 className="title-white">{titleWhite}</h3>
              <h3 className="title-blue">{titleBlue}</h3>
            </div>
            <div
              className={
                reverse
                  ? "image-text-box-description"
                  : "image-text-box-description"
              }
            >
              <p>{description}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Frame.propTypes = {
  imageUrl: PropTypes.string,
  imageAlt: PropTypes.string,
  reverse: PropTypes.bool,
  titleWhite: PropTypes.string,
  titleBlue: PropTypes.string,
  description: PropTypes.string,
};

export default Frame;
