import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavScrollTop from "./components/nav-scroll-top";
import Home from "./pages/home";
import About from "./pages/about";
import Certificates from "./pages/certificates";
import Vision from "./pages/vision";
import MiningTunneling from "./pages/mining_tunneling";
import Applications from "./pages/applications";
import RdLaboratory from "./pages/rd_laboratory";
import Communication from "./pages/communication";
import "./App.css"
import CoalMining from "./pages/detailsPages/coalMining";
import FireSuppressionAndInsulationPreventingAirAndGasLeakage from "./pages/detailsPages/fireSuppressionAndInsulationPreventingAirAndGasLeakage";
import HardRockMining from "./pages/detailsPages/hardRockMining";
import TunnelingDetailsPage from "./pages/detailsPages/tunnelingDetailsPage"
import VoidFillingRoofSupportGoafSealingBackFilling from "./pages/detailsPages/voidFillingRoofSupportGoafSealingBackFilling";
import WaterControl from "./pages/detailsPages/waterControl";
import StrataControlImprovingStability from "./pages/detailsPages/strataControlImprovingStability";
import NotFound from "./pages/not_found";

function App() {
  return (
    
    <Router basename="/">
      <NavScrollTop>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about_us" element={<About />} />
          <Route exact path="/our_certificates" element={<Certificates />} />
          <Route exact path="/sustainability_vision" element={<Vision />} />
          <Route exact path="/mining_tunneling" element={<MiningTunneling />} />
          <Route exact path="/applications" element={<Applications />} />
          <Route
            exact
            path="/rd_and_laboratory_studies"
            element={<RdLaboratory />}
          />
          <Route exact path="/communication" element={<Communication />} />
          <Route exact path="/coal_mining" element={< CoalMining/>} />
          <Route exact path="/fire-suppression-and-insulation-preventing-air-and-gas-leakage" element={<FireSuppressionAndInsulationPreventingAirAndGasLeakage />} />
          <Route exact path="/hard-rock-mining" element={<HardRockMining />} />
          <Route exact path="/tunneling" element={< TunnelingDetailsPage/>} />
          <Route exact path="/void-filling-roof-support-goaf-sealing-back-filling" element={<VoidFillingRoofSupportGoafSealingBackFilling />} />
          <Route exact path="/water-control" element={< WaterControl/>} />
          <Route exact path="/strata-control-improving-stability" element={<StrataControlImprovingStability />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </NavScrollTop>
    </Router>
  );
}

export default App;
