import React from "react";

const FooterBottom = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer-copyright-social">
      <div className="footer-copyright">
        <p>
          Copyright © {currentYear} | OK Foreign Trade & Mining | All rights
          reserved.
        </p>
      </div>
      <div className="footer-social">
        This website is proudly crafted by{" "}
        <a
          className="osicrew_link"
          href="https://osicrew.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Osicrew
        </a>
        .
      </div>
    </div>
  );
};

export default FooterBottom;
