import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const Logo = ({ image }) => {
    if (!image) {
        console.error("Image prop is required for Logo component.");
        return null;
    }

    return (
        <Link to={"/"}>
            <img src={image} alt="okdtm_logo" />
        </Link>
    );
};

Logo.propTypes = {
    image: PropTypes.string.isRequired, // image prop'unun zorunlu olduğunu belirttik
};

export default Logo;
