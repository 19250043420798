import React from "react";
import Layout from "../../layouts/layout";
import Banner from "../../components/banner";
import Presentation2 from "../../components/presentation2";
import Carousel from "../../components/carousel";
import RdData from "../../data/rd_laboratory/rd_laboratory.json";
import SEO from "../../components/seo";

const RdLaboratory = () => {
  return (
    <>
      <SEO
        title="R&D and Laboratory Studies"
        metaDesc="Our expert team is continuously engaged in research and development activities to address the challenges faced in underground mining and tunnel construction."
        canonical="/rd_and_laboratory_studies"
      />
      <Layout>
        <Banner
          title=" R&D and"
          titleBlue=" Laboratory Studies"
          imageUrl="/assets/images/rd-img-8.png"
          imageBool={true}
        />
        <Presentation2
          title2="Our R&D Laboratory"
          description="Our R&D laboratory works to develop innovative solutions and high-performance products. Our expert team is continuously engaged in research and development activities to address the challenges faced in underground mining and tunnel construction. As OK FOREIGN TRADE AND MINING INC., our laboratories are accredited by the National Accreditation Centre (NAC) according to the ISO 17025 Standard. With this accreditation certificate, our test reports are issued as globally valid quality assurance system-approved reports under the scope of the Asia Pacific Accreditation Cooperation (APAC) and the International Laboratory Accreditation Cooperation (ILAC) Mutual Recognition Arrangement (MRA).
In our laboratories, internal and external quality control studies are conducted using international standards and the latest technology devices, and these processes are certified through audits by international organizations. Therefore, as OK Mining, we aim to maintain the highest level of customer satisfaction and provide the most reliable test results.
Our ISO 17025 accreditation guarantees that all our analysis and testing processes comply with international norms and high-quality standards. This accreditation not only ensures global recognition of our laboratories but also reinforces our commitment to providing reliable and verifiable results to our customers.
As OK FOREIGN TRADE AND MINING INC., we will continue to offer our services using the most up-to-date technologies and methodologies within the framework of our continuous improvement and excellence approach. Our accredited laboratories are determined to maintain their leading position in the industry by combining reliability, accuracy, and quality."
        />
        <Carousel data={RdData.iconBoxIcons} />
      </Layout>
    </>
  );
};

export default RdLaboratory;
