import React from "react";
import "./index.css";

const App = () => {
  return (
    <>
      {" "}
      <div className="map-and-address">
        <div className="container">
          <div className="left shadow-contact">
            <img
              src="/assets/images/center_img.png"
              alt="Building"
              className="image"
            />
            <div className="address">
              <h2>Center</h2>
              <a href="https://maps.app.goo.gl/6vgbrjo5uw2NBSoSA" target="_blank">O.D.T.Ü. İkizler Ar-Ge Binası No:1 06800 Ankara/TÜRKİYE</a>
              <a href="tel:+903122101604">Phone: +90 (312) 210 16 04</a>
              <a>Fax: +90 (312) 210 16 02</a>
              <a href="mailto:info@okftm.com">Mail: info@okftm.com</a>
            </div>
          </div>
          <div className="right shadow-contact">
            <div className="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1601.0333434707077!2d32.77492833767612!3d39.89661890118289!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d3463a5d6752f1%3A0xbf953ae4b36d3b4!2zT0RUw5wgVEVLTk9LRU5UIMSwa2l6bGVyIEJpbmFzxLE!5e0!3m2!1str!2str!4v1718105331740!5m2!1str!2str"
                width={600}
                height={450}
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="map-and-address">
        <div className="container">
          <div className="left shadow-contact">
            <img
              src="/assets/images/factory_img.png"
              alt="Building"
              className="image"
            />
            <div className="address">
              <h2>Factory</h2>
              <a href="https://maps.app.goo.gl/7nj8FXS3E8VU9Xv58" target="_blank">ASO 2 OSB 2020. Cad No: 7 Ankara/TÜRKİYE</a>
              <a href="tel:+903122101604">Phone: +90 (312) 210 16 04</a>
              <a>Fax: +90 (312) 210 16 02</a>
              <a href="mailto:info@okftm.com">Mail: info@okftm.com</a>
            </div>
          </div>
          <div className="right shadow-contact">
            <div className="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3065.379505420521!2d32.440796682516996!3d39.798478298178246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d31eacc4828ab9%3A0x189298c27fea9da8!2sOk!5e0!3m2!1str!2str!4v1718105233522!5m2!1str!2str"
                width={600}
                height={450}
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default App;
