import React from "react";
import Layout from "../../layouts/layout";
import Banner from "../../components/banner";
import "./index.css";
import ContactForm from "../../components/contact-form";
import AddressAndMap from "../../components/addressAndMap";
import SEO from "../../components/seo";


const Communication = () => {
  return (
    <>
    <SEO title="Communication" metaDesc="Explore our contact information, reach out to our support team, and stay updated with our latest news and announcements." canonical="/communication" />
      <Layout>
        <Banner
          title="Communication"
          titleBlue=""
          imageUrl=""
          imageBool={false}
        />
       
<ContactForm />
<AddressAndMap />


      </Layout>
    </>
  );
};

export default Communication;
