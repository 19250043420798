import React from 'react'
import Layout from '../../../layouts/layout'
import DetailsPage from '../../../components/detailsPage'
import SEO from "../../../components/seo"

const WaterControl = () => {
  return (
    <>  
    
    <SEO title="Water Control" metaDesc="Water control is a critical aspect of maintaining safety and operational efficiency in underground mines and tunnels." canonical="/water-control" />
      <Layout>
    
    <DetailsPage
     title="Water Control"
     titleBlue=""
     title2=""
     titleBlue2=""
     description1="Water control is a critical aspect of maintaining safety and operational efficiency in underground mines and tunnels. Injected chemicals PETRY-N™️ play a vital role in managing groundwater ingress, stabilizing the surrounding rock and soil, and preventing water-related issues"
     description2=""
     description3=""
     description4=""
     imageUrl="/assets/images/detailsPage/tunnel_img_world.png"
     tradeMark={false}
     imageBool={true}
    />
    
    </Layout>
    </>
  )
}

export default WaterControl