import React, { useState, useRef } from "react";
import "./index.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuidv4 } from "uuid";
import CryptoJS from "crypto-js";

const ContactForm = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [subject, setSubject] = useState("-");
  const form = useRef();
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isButtonDisabled) {
      toast.error("Formu tekrar iletebilmek için lütfen sayfayı yenileyiniz.", { autoClose: 4500 });
      return;
    }

    setIsButtonDisabled(true);

    const encryptedText = CryptoJS.AES.encrypt(
      uuidv4(),
      process.env.REACT_APP_SSK
    ).toString();

    const FD = new FormData(form.current);
    FD.append("accessToken", encryptedText);

    const obj = {
      message: FD.get("message"),
      userInfo: FD.get("name") + " " + FD.get("surname"),
      from: FD.get("email"),
      subject: FD.get("subject"),
      tel: FD.get("phone"),
      ownerName: "osiwebmail@gmail.com",
      to: "info@okftm.com",
      host: "smtp.gmail.com",
      service: "gmail",
      accessToken: encryptedText,
    };

    try {
      const res = await axios.post(
        "https://nodemailer.3wweb.org/",
        obj,
        {
          auth: {
            username: "OSICrew_Node_Mailler",
            password: "1786oSi?173",
          },
        }
      );

      if (res) {
        setIsSuccess(true);
        toast.success("Mesajınız gönderildi.", { autoClose: 4500 });
      }
    } catch (error) {
      toast.error(error.message, { autoClose: 4500 });
      setIsButtonDisabled(false);
    }
  };

  const handleButtonClick = (e) => {
    if (isButtonDisabled) {
      e.preventDefault();
      toast.error("Formu tekrar iletebilmek için lütfen sayfayı yenileyin.", { autoClose: 4500 });
    }
  };

  return (
    <>
      <div className="contact-form">
        <h2>
          Contact<span>Form</span>
        </h2>
        <form id="contact-form" ref={form} onSubmit={handleSubmit}>
          <div className="container form-main-div">
            <div className="row">
            <div className=" col-lg-6 col-md-12 contact-input">
              <input
                className="phone-input"
                type="text"
                name="phone"
                placeholder="Phone number"
              />
              <input
                type="email"
                name="email"
                placeholder="E-mail address"
                className="phone-input"
              />
              <div className="d-flex justify-content-between">
              <input
                className="name-input"
                type="text"
                name="name"
                placeholder="Name"
              />
              <input
                className="surname-input"
                type="text"
                name="surname"
                placeholder="Surname"
              /></div>
              <input
                className="d-none"
                type="text"
                name="subject"
                placeholder="Subject"
                value={subject}
              />
            </div>
            <div className="col-lg-6 col-md-12 mb-3">
              <textarea
                name="message"
                placeholder="Message"
                className="mb-1"
              ></textarea>
            </div>
            <div className="submit-button">
              <button
                type="submit"
              
              >
                Send
              </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <ToastContainer />
    </>
  );
};

export default ContactForm;
