import React, { useEffect, useState } from 'react';
import data from '../../../data/wrappers/menu.json'; 
import PropTypes from 'prop-types';
import './index.css';

const Links = ({ selectedMenu }) => {
  const [footerMenu, setFooterMenu] = useState([]);

  useEffect(() => {
    
    setFooterMenu(data[selectedMenu]);
  }, [selectedMenu]); 

  return (   <ul className='footer-list'>
  {footerMenu.map((link, index) => (
   <li className="footer-list-li" key={index}>
   
   <a
     href={link.link}
     target={selectedMenu === "footerCommunicationMenu" && link.title !== "Communication" ? "_blank" : ""}
   >
     {link.title}
   </a>
 </li>
 
  ))}
</ul>



  );
};

Links.propTypes = {
  selectedMenu: PropTypes.string.isRequired, 
};

export default Links;
