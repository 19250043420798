import React from 'react'
import Layout from '../../../layouts/layout'
import DetailsPage from '../../../components/detailsPage'
import SEO from '../../../components/seo'

const VoidFillingRoofSupportGoafSealingBackFilling = () => {
  return (
    <>    
    <SEO title="Void Filling - Roof Support-Goaf Sealing - Back Filling" metaDesc="Goaf sealing and backfilling are crucial processes in underground coal mining that aim to enhance safety, prevent air and gas leaks, and maintain structural stability." canonical="/void-filling-roof-support-goaf-sealing-back-filling" />
    <Layout>
    
    <DetailsPage
     title="Void Filling - Roof Support-Goaf Sealing - Back Filling"
     titleBlue=""
     title2=""
     titleBlue2=""
     description1="NAROFLEKS™️ Phenolic Foam is increasingly being used for void filling and roof support in underground mines and tunnels due to its unique properties such as fire resistance, thermal insulation, and structural integrity "
     description2="Goaf sealing and backfilling are crucial processes in underground coal mining that aim to enhance safety, prevent air and gas leaks, and maintain structural stability. These techniques are integral to effective mine management and environmental protection."
     description3=""
     description4=""
     imageUrl="/assets/images/detailsPage/tunnel_img_world.png"
     tradeMark={false}
     imageBool={true}
    />
    
    </Layout>
    </>
  )
}

export default VoidFillingRoofSupportGoafSealingBackFilling