import React from "react";
import Layout from "../../layouts/layout";
import Banner from "../../components/banner";
import Carousel from "../../components/carousel";
import CertData from "../../data/certificates/certificates.json";
import SEO from "../../components/seo";

const Certificates = () => {
  return (
    <>
      <Layout>
        <SEO
          title="Our Certificates"
          metaDesc="Discover our extensive collection of certifications that demonstrate our commitment to quality, excellence, and industry standards."
          canonical="/our_certificates"
        />

        <Banner
          title="Our"
          titleBlue=" Certificates"
          imageUrl=""
          imageBool={false}
        />
        <Carousel data={CertData.ourCertificates} page="certificates" />
      </Layout>
    </>
  );
};

export default Certificates;
