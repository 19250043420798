import React from "react";

const Presentation = () => {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="about-title">
            <h2 className="text-start">Mission Statement</h2>
          </div>
          <div className="about-title_blue">
            <h3>“Fostering Stability, Ensuring Safety’’</h3>
          </div>
          <div className="about-content">
            <p>
              Our mission is to pioneer innovative risk-mitigating technologies
              that redefine safety standards across industries. At the heart of
              our commitment is a vision for a safer, more resilient future,
              where our technologies become synonymous with trust and
              reliability. Our commitment extends to cost-effectiveness,
              offering competitive pricing that not only enhances affordability
              but also boosts productivity, delivering unparalleled value. At
              the forefront of our success is our relentless pursuit of
              innovation, where our dedicated R&D team works tirelessly to
              ensure our products remain ahead of industry standards, embodying
              the spirit of progress and reliability.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Presentation;
