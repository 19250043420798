import React from 'react'
import PropTypes from "prop-types"
import "./index.css"

const DetailsPage = ({ title, titleBlue, title2, titleBlue2, description1, description2, description3, description4, imageUrl, tradeMark, imageBool }) => {
 
  return (
    <>
      <div className="container banner page-details">
        <div className="row first_row">
          <div className="col-12">
            <h1>
              {title}
              {titleBlue && (
                <span style={{ color: "#3B82F6", marginLeft: "0.75rem" }}>
                  {titleBlue}
                  {tradeMark && <span className="trademark color-white">TM</span>}
                </span>
              )}
            </h1>
            {title2 && (
              <h1 style={{ marginLeft: "0.75rem" }}>
                {title2}
                {titleBlue2 && (
                  <span style={{ color: "#3B82F6", marginLeft: "0.75rem" }}>
                    {titleBlue2}
                    {tradeMark && <span className="trademark color-white">TM</span>}
                  </span>
                )}
              </h1>
            )}
          </div>
        </div>
        {imageBool && (
          <div className="row second_row">
            <div className="col-12">
              <img src={imageUrl} alt="about_us_slider" />
            </div>
            <div className="col-12">
              <p className='description'>{description1}</p>
              <p className='description'>{description2}</p>
              <p className='description'>{description3}</p>
              <p className='description'>{description4}</p>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

DetailsPage.propTypes = {
  title: PropTypes.string,
  titleBlue: PropTypes.string,
  title2: PropTypes.string,
  titleBlue2: PropTypes.string,
  description1: PropTypes.string,
  description2: PropTypes.string,
  description3: PropTypes.string,
  description4: PropTypes.string,
  imageUrl: PropTypes.string,
  tradeMark: PropTypes.bool,
  imageBool: PropTypes.bool
};

export default DetailsPage
