import React, { Fragment } from "react";
import Layout from "../../layouts/layout";
import SEO from "../../components/seo";
import Slider from "../../components/slider";
import IconBox from "../../components/icon-box";
import Frame from "../../components/frame";
import "./index.css";
import Slogan from "../../components/slogan";
import Carousel from "../../components/carousel";
import RdData from "../../data/rd_laboratory/rd_laboratory.json";

const Home = () => {
  return (
    <Fragment>
      <Layout>
        <SEO title="OK Foreign Trade & Mining – Official Page" metaDesc="Distinguished leader in the production of cutting-edge injection chemicals tailored to meet your specific industry requirements." canonical="" />
        <Slider videoLink="/assets/video/okdtm_video.mp4" />
        <Carousel data={RdData.iconBoxIconsHome} />
        <IconBox />

        <div className="container section-mining-tunneling">
          <div className="row">
            <div className="col-12">
              <div className="section-title-white">
                <h1 className="d-inline">
                  Mining 4.0<span className="trademark">TM </span>
                {/* </h1>
                <h1 className="d-inline"> */}
                  <span className="ms-5">Tunneling 4.0</span><span className="trademark">TM</span>
                </h1>
              </div>
              <div className="section-title-blue">
                <h3>
                  Redefining Excellence in Chemical Solutions for Mining and
                  Tunneling
                </h3>
              </div>
              <div className="section-title-description">
                <p>
                  Mining 4.0<sup>TM</sup> and Tunneling 4.0<sup>TM</sup> embody a groundbreaking approach
                  within the mining and tunneling sectors. This trademark
                  introduces a novel perspective to the industry by
                  incorporating pivotal elements including smart manufacturing,
                  real-time decision-making, augmented productivity,
                  flexibility, and agility.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="section-title-image">
                <img
                  src="/assets/images/4.0_img.png"
                  alt="mining 4.0TM and tunneling 4.0TM"
                />
              </div>
            </div>
          </div>
        </div>

        <Frame
          imageUrl="/assets/images/hardrock.png"
          imageAlt="mining 4.0TM and tunneling 4.0TM"
          reverse={false}
          titleWhite="Hard Rock"
          titleBlue="Mining"
          description="Chemical injections are a vital technique in underground hard rock mining, addressing critical issues such as ground stability, water control, dust suppression, and gas management. When implemented effectively, they significantly enhance safety, operational efficiency, and environmental protection, making them an indispensable tool in modern mining operations."
          buttonLink="/hard-rock-mining"
          buttonText="More"
        />
        <Frame
          imageUrl="/assets/images/coal.png"
          imageAlt="coal mining"
          reverse={true}
          titleWhite="Coal"
          titleBlue="Mining"
          description="Underground coal mining requires careful attention to safety. Chemical injection and foam applications are vital tools for improving worker safety and operational efficiency in this industry. With the proper techniques, underground coal mining can be made safer and more sustainable."
          buttonLink="/coal_mining"
          buttonText="More"

          

        />
        <Frame
          imageUrl="/assets/images/tunneling.png"
          imageAlt="tunneling 4.0TM"
          reverse={false}
          titleWhite="Tunneling"
          titleBlue=""
          description="Tunnel construction is a complex and challenging process, requiring the use of appropriate techniques to create safe and durable structures. Chemical injection is one such technique and plays a significant role in tunneling. With the selection of the right chemical substances and appropriate application methods, tunnel structures can be made safer, more durable, and longer-lasting."
          buttonLink="/tunneling"
          buttonText="More"


        />

        <Slogan />
      </Layout>
    </Fragment>
  );
};

export default Home;
