import React from 'react'
import PropTypes from "prop-types";
import './index.css'


const Slider = ({videoLink}) => {

   
  return (
    <>
    <video className='slider_video' autoPlay muted loop  id="sliderVideo">
        <source src={videoLink} type="video/mp4" />
    </video>
    </>
  )
}


Slider.propTypes = {
    videoLink: PropTypes.string.isRequired, // image prop'unun zorunlu olduğunu belirttik
};

export default Slider