import React from "react";
import "./index.css";

const miningDesc = () => {
  return (
    <>
      <div className="container mining">
        <h2 className="main-title">
          Redefining Excellence in Chemical Solutions for Mining and Tunneling
        </h2>
        <p className="intro">
          Mining 4.0<sup>TM</sup> and Tunneling 4.0<sup>TM</sup> embody a groundbreaking approach within
          the mining and tunneling sectors. This trademark introduces a novel
          perspective to the industry by incorporating pivotal elements
          including smart manufacturing, real-time decision-making, augmented
          productivity, flexibility, and agility.
        </p>
        <h2 className="trademark-title">
          Mining 4.0<sup>TM</sup>
        </h2>
        <p className="details">
          Mining 4.0<sup>TM</sup> and Tunneling 4.0<sup>TM</sup> redefine
          excellence in chemical solutions within the mining and tunneling
          sectors. While preserving our expertise in mass production, we also
          maintain our leadership position in customized production. Our
          products such as Narofleks<sup>TM</sup> and Petryflek<sup>TM</sup> are
          designed to meet the specific needs of our customers. Discover a level
          of excellence surpassing industry standards with Mining 4.0
          <sup>TM</sup> and Tunneling 4.0<sup>TM</sup>.
        </p>
        <p className="custom-products">
          <a href="#">
            All our products under Mining 4.0<sup>TM</sup> and Tunneling 4.0
            <sup>TM</sup> are custom-made according to the specific requirements
            of our customers.
          </a>
        </p>
        <h3 className="features-title">Features</h3>
        <ul className="features-list">
          <li>
            <span>Smart Manufacturing:</span> Mining 4.0<sup>TM</sup>{" "}
            emphasizes efficiency and smart management in production processes.
            Smart systems integrated with sensors in the factory optimize
            processes and enhance productivity.
          </li>
          <li>
            <span>Real-Time Decision-Making:</span> Instant data analysis
            enables businesses to make real-time decisions, facilitating
            adaptability to rapidly changing conditions.
          </li>
          <li>
            <span>Flexibility and Agility:</span> Mining 4.0<sup>TM</sup>{" "}
            adopts flexible and agile production processes to respond quickly to
            customer needs, enabling mass customization.
          </li>
          <li>
            <span>Customized Production:</span> Mining 4.0<sup>TM</sup>{" "}
            focuses on the unique needs of each customer, symbolizing excellence
            in custom production. Products such as Narofleks<sup>TM</sup> and
            Petryflek<sup>TM</sup> are tailored to the specific requirements of
            our customers.
          </li>
          <li>
            <span>Transparent and Efficient Supply Chain:</span> Mining 4.0
            <sup>TM</sup> optimizes material flow and delivers faster service to
            customers by establishing a transparent and efficient supply chain.
          </li>
          <li>
            <span>Backward Integration:</span> As a company, we embrace
            backward integration within the framework of Mining 4.0<sup>TM</sup>
            . By producing our own raw materials, we maintain control over the
            quality and consistency of our products.
          </li>
        </ul>
      </div>
    </>
  );
};

export default miningDesc;
