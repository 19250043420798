import React from "react";
import "./index.css";
import Logo from "../../components/logo";
import Links from "../../components/footer/links";
import FooterBottom from "../../components/footer/footerBottom";
import { FaInstagram, FaLinkedin, FaXTwitter, FaYoutube, FaFacebookF } from "react-icons/fa6";
import LanguageSelect from "../../components/language-select";


const Footer = () => {


  return (
    <div className="footer-okdtm section ">
      <div className="container-fluid">
        <div className="footer-info-box d-flex justify-content-between">
          <div className="footer-logo-box">
            <Logo image={`/assets/svg/okdtm_logo2-01.svg`} />
            <div>
              <p>Welcome to OK Foreign Trade & Mining, a distinguished leader in the production of cutting-edge injection chemicals tailored to meet your specific industry requirements. With our prominent trademarks, Mining 4.0<sup>TM</sup> and Tunnelling 4.0<sup>TM</sup>, we stand at the forefront of innovation, delivering solutions that redefine standards in underground mining and tunneling. With a legacy spanning over three decades, our company has solidified its position as a trusted industry player, distinguishing itself among a select group of manufacturers globally.</p>
             
            </div>
            <div className="social-box">
            <a target="_blank" href="https://www.linkedin.com/company/ok-foreign-trade-mining-inc"> <FaLinkedin /></a>

            {/* <FaInstagram /> 
             <FaXTwitter />
            <FaYoutube />
            <FaFacebookF />  */}
            </div>
          </div>
          <div className="footer-pages-box">
           <Links selectedMenu="footerPagesMenu"/>
          </div>
          <div className="footer-applications-box">
            <Links  selectedMenu="footerApplicationsMenu"/>/
          </div>
          <div className="footer-communication-box">
          <Links  selectedMenu="footerCommunicationMenu"/>/
          <LanguageSelect/>
          </div>
        </div>

      <FooterBottom/>
      </div>
    </div>
  );
};

export default Footer;
