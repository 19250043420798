import React from "react";
import PropTypes from "prop-types";
import "./index.css";

const Frame = ({
  imageUrl,
  imageAlt,
  reverse,
  titleWhite,
  titleBlue,
  description,
  titleWhiteTwo,
  titleBlueTwo,
  descriptionTwo,
  buttonLink,
  buttonText,
  number,
  animationUrl,

}) => {
  return (
    <>
      <div
        className={
          reverse
            ? "image-frame-box-image-text-box flex-row-reverse"
            : "image-frame-box-image-text-box"
        }
      >
        <div className="image-frame-box col-6">
          <div className="main-border-div">
            <div className="main-border">
              <div className="border-container">
                <div className="border-top-left" />
                <div className="border-bottom-right" />
                
          {number ? (<p className="application-number">{number}</p>) : null}  
         
                <div className="content">
                  {animationUrl ? (
                    <video
                      className="animation_video"
                      autoPlay
                      loop
                      muted
                      playsInline
                      src={animationUrl}
                    />
                  ) :  <img className="image-image" src={imageUrl} alt={imageAlt} />}
                 
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="image-text-box col-6 ">
         {titleWhiteTwo || titleBlueTwo || descriptionTwo ? (
          <>
          <div
            className={
              reverse
                ? "justify-content-end image-text-box-title"
                : "image-text-box-title"
            }
          >
            {titleWhite ? (<h3 className="title-white">{titleWhite}</h3>) : null} 
            {titleBlue ? (<h3 className="title-blue">{titleBlue}</h3>) : null}
          </div>
          <div
            className={
              reverse
                ? "text-end image-text-box-description"
                : "image-text-box-description"
            }
          >
           {description ? (<p>{description}</p>) : null} 
          </div> 
          <div
            className={
              reverse
                ? "justify-content-end image-text-box-title"
                : "image-text-box-title"
            }
          >
            {titleWhiteTwo ? (<h3 className="title-white">{titleWhiteTwo}</h3>) : null} 
            {titleBlueTwo ? (<h3 className="title-blue">{titleBlueTwo}</h3>) : null}
          </div>
          <div
            className={
              reverse
                ? "text-end image-text-box-description"
                : "image-text-box-description"
            }
          >
           {descriptionTwo ? (<p>{descriptionTwo}</p>) : null} 
          </div>
        </>) :  (
          <>
           <div
            className={
              reverse
                ? "justify-content-end image-text-box-title"
                : "image-text-box-title"
            }
          >
            {titleWhite ? (<h3 className="title-white">{titleWhite}</h3>) : null} 
            {titleBlue ? (<h3 className="title-blue">{titleBlue}</h3>) : null}
          </div>
          <div
            className={
              reverse
                ? "text-end image-text-box-description"
                : "image-text-box-description"
            }
          >
           {description ? (<p>{description}</p>) : null} 
          </div> 
          </>
        )
          } 

          <div
            className={
              reverse
                ? "text-end submit-button home-button"
                : "justify-content-start submit-button home-button"
            }
          >
            <a href={buttonLink}><button>{buttonText}</button></a>
          </div>
        </div>
      </div>
    </>
  );
};

Frame.propTypes = {
  imageUrl: PropTypes.string,
  imageAlt: PropTypes.string,
  reverse: PropTypes.bool,
  titleWhite: PropTypes.string,
  titleBlue: PropTypes.string,
  description: PropTypes.string,
  animationUrl: PropTypes.string,
};

export default Frame;
