import React from "react";
import "./index.css";

const SustainabilityVisionDesc = () => {
  return (
    <>
      <div className="container mining">
        <h2 className="main-title text-start">
        At OK Foreign Trade and Mining Inc., sustainability is at the core of our mission.        </h2>
        <p className="intro">
        We are dedicated to leaving a positive footprint on society, the economy, and the environment while setting the benchmark for innovation through transparent leadership. As leaders in Risk Mitigating Technologie of Injection Chemicals in Underground Mining, our foremost goal is to ensure the safety of miners while contributing to the economic extraction of minerals and minimizing our carbon footprint.
        </p>
       
        <p className="details">
        Our sustainability vision is built on three pillars, each supported by concrete steps: 
        </p>
        
        <ul className="features-list sustainability vision">
          <li>
          Environmental Transparency:
          </li>
          <li>
          Investing in renewable energy and energy-efficient technologies to minimize our carbon footprint
          </li>
          <li>
          Striving for minimal waste through comprehensive recycling and waste management programs.
          </li>
          <li>
          Social Responsibility:
          </li>
          <li>
          Upholding fair labor practices and safeguarding human rights within our operations and supply chain.
          </li>
          <li>
          Engaging and supporting local communities through education and development programs
          </li>
          <li>
          Promoting diversity, equity, and inclusion across our workforce.
          </li>
          <li>
          Economic Growth:
          </li>
          <li>
          Ensuring long-term economic sustainability through responsible financial practices.
          </li>
          <li>
          Continuously innovating to improve the efficiency and safety of our products.
          </li>
          <li>
          Maintaining transparent communication and accountability in all our business dealings.
          </li>
         
        </ul>
        <p className="details">
        By focusing on these pillars, OK Foreign Trade and Mining Inc. is committed to leading the way in sustainable practices within the mining industry, ensuring a safer, more equitable, and environmentally friendly future.        </p>
      </div>
    </>
  );
};

export default SustainabilityVisionDesc;
