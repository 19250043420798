import React from 'react';
import { useNavigate } from 'react-router-dom';
import './index.css';

const NotFound = () => {
  const navigate = useNavigate();

  const goToHomePage = () => {
    navigate('/');
  };

  return (
    <div className="not-found">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="not-found-content">
              <h1>404</h1>
              <h2>Page not found</h2>
              <p>Sorry, the page you are looking for does not exist.</p>
              <button onClick={goToHomePage} className="home-buttonnn">Go to Homepage</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
