import React from 'react'
import Layout from '../../../layouts/layout'
import DetailsPage from '../../../components/detailsPage'
import SEO from '../../../components/seo'

const StrataControlImprovingStability = () => {
  return (
    <>   
    
    <SEO title="Strata Control - Improving Stability" metaDesc="PETRY-N™️ is an effective solution for strata control in underground mines and tunnels, offering high strength, flexibility, and rapid curing." canonical="/strata-control-improving-stability" />
     <Layout>
    
    <DetailsPage
     title="Strata Control - Improving Stability"
     titleBlue=""
     title2=""
     titleBlue2=""
     description1="PETRY-N™️ is an effective solution for strata control in underground mines and tunnels, offering high strength, flexibility, and rapid curing. Its ability to reinforce rock masses, provide protective coatings, and control water ingress makes PETRY-N™️ a valuable tool for maintaining stability and safety in subterranean environments."
     description2=" PETRY-N™️ is a hybrid material combining the properties of polyurea and silicate, offering significant benefits for improving stability in underground mines and tunnels. This advanced material is known for its strength, flexibility, and rapid curing time, making PETRY-N™️ an ideal choice for reinforcing and stabilizing subterranean structures."
     description3=""
     description4=""
     imageUrl="/assets/images/detailsPage/tunnel_img_world.png"
     tradeMark={false}
     imageBool={true}
    />
    
    </Layout>
    </>
  )
}

export default StrataControlImprovingStability