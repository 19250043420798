import React from "react";
import "./index.css";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Carousel = ({ data, page }) => {
  const IconData = data || [];
  return (
    <>
      <div className="icon-box">
        {IconData.map((icon, index) => (
          <div className= {page==="certificates" ? "main-border-div certificates" : "main-border-div"}  key={index}>
            <div className="main-border">
              <div className="border-container carousel">
                <div className="border-top-left carousell" />
                <div className="border-bottom-right carousell" />
                <div className="content">
                  
                  {icon.link ? ( 
                      <a href={icon.link} target="_blank" rel="noreferrer">
                      <img
                        className="icon-icon carousel"
                        src={icon.icon_url}
                        alt={icon.alt}
                      />
                      </a> ) : (
                        <img
                        className="icon-icon carousel"
                        src={icon.icon_url}
                        alt={icon.alt}
                      />
                      )
                    }
                  
                
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

Carousel.propTypes = {
  data: PropTypes.array.isRequired, 
  page: PropTypes.string,
};

export default Carousel;
