import React from 'react'
import Layout from '../../../layouts/layout'
import DetailsPage from '../../../components/detailsPage'
import SEO from "../../../components/seo";

const Tunneling = () => {
  return (
    <>   
    <SEO title="Tunneling" metaDesc="Tunnel construction is a complex and challenging process, requiring the use of appropriate techniques to create safe and durable structures." canonical="/tunneling" />
    
     <Layout>
    
    <DetailsPage
     title="Tunneling"
     titleBlue=""
     title2=""
     titleBlue2=""
     description1="Tunnel construction is a complex and challenging process, requiring the use of appropriate techniques to create safe and durable structures. Chemical injection is one such technique and plays a significant role in tunneling. With the selection of the right chemical substances and appropriate application methods, tunnel structures can be made safer, more durable, and longer-lasting."
     description2=""
     description3=""
     description4=""
     imageUrl="/assets/images/detailsPage/tunnel_img.png"
     tradeMark={false}
     imageBool={true}
    />
    
    </Layout>
    </>
  )
}

export default Tunneling