import React from "react";
import "./index.css";

function Banner({ title, titleBlue, title2, titleBlue2, imageUrl, tradeMark, imageBool, pages }) {
  return (
    <div className={"container banner " + (imageBool ? pages : "not-image")}>
      <div className="row first_row">
        <div className="col-12">
          <h1>
            {title}
            {titleBlue && (
              <span style={{ color: "#3B82F6", marginLeft: "0.75rem" }}>
                {titleBlue} {tradeMark ? <span className="trademark color-white">TM</span> : null}
              </span>
            )}
            {title2 && (
              <span style={{ marginLeft: "0.75rem" }}>{title2}</span>
            )}
            {titleBlue2 && (
              <span style={{ color: "#3B82F6", marginLeft: "0.75rem" }}>
                {titleBlue2} {tradeMark ? <span className="trademark color-white">TM</span> : null}
              </span>
            )}
          </h1>
        </div>
      </div>
      {imageBool && (
        <div className="row second_row">
          <div className="col-12">
            <img src={imageUrl} alt="about_us_slider" />
          </div>
        </div>
      )}
    </div>
  );
}

export default Banner;
