import React, {useState} from 'react'
import "./index.css"

const LanguageSelect = () => {


    const [language, setLanguage] = useState('English');
    const [dropdownOpen, setDropdownOpen] = useState(false);
  
    const toggleDropdown = () => {
      setDropdownOpen(!dropdownOpen);
    };
  
    const selectLanguage = (lang) => {
      setLanguage(lang);
      setDropdownOpen(false);
    };

  return (
    <>
    
    <div className="language-selector">
            <div className="selected-language" onClick={toggleDropdown}>
              <span className="globe-icon"><img src="/assets/svg/world.svg" alt="world_icon" /></span>
              <span>{language}</span>
              <span className='arrow-icon-dropdown'>  
              <img
                  alt="ok_icon"
                  className={`arrow-hover-dropdown ${dropdownOpen ? "arrow-dropdown" : "arrow-dropdown-exit"}`}
                  src="/assets/svg/ok_white.svg"
                /></span>
            </div>
            <div className={`language-dropdown-menu ${dropdownOpen ? "animate-dropdown" : "animate-dropdown-exit"}`}>
              <div className="dropdown-item" onClick={() => selectLanguage(language === "English" ? "Türkçe" : "English")}>{language === "English" ? "Türkçe" : "English"}</div>
            </div>
          </div>
    
    
    
    
    </>
  )
}

export default LanguageSelect