import React from "react";
import "./index.css";

const Slogan = () => {
  return (
    <>
      <div className="container-fluid container-slogan">
        <img
          src="/assets/svg/logo_vector.svg"
          alt="Mascot"
          className="mascot"
        />
        <h4 className="wordCarousel text">
          Ready to be <span>your local p</span>
          <div>
            <ul className="flip2">
              <li>roducer</li>
              <li>artner</li>
            </ul>
          </div>
        </h4>
      </div>
    </>
  );
};

export default Slogan;
