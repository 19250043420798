import React, { Fragment } from "react";
import Header from "../header";
import Footer from "../footer";
import PropTypes from "prop-types";
const Layout = ({ children }) => {
  return (
    <Fragment>
      <Header />
      {children}
    
      <Footer />
    </Fragment>
  );
};
Layout.propTypes = {
  children: PropTypes.any,
};

export default Layout;